<template>
  <div :style="slidePosition" class="h-100 z-10 slide25Wrapper" style="width:100%;">
    <inline-svg src="images/triumf-50-mark.svg" class="position-absolute" aria-label="TRIUMF 50 mark"
      style="width:44%;left:50%;top:40%;transform:translate(-50%,-50%)" />
    <inline-svg src="images/finalLine.svg" class="position-absolute finalLine" aria-label="TRIUMF MARK line"
      style="width:105%;left:5%;top:10%;" />
    <div class="position-absolute rounded-circle bg-warning circle25"
      style="width:4vh;height:4vh;left:5%;top:10%; transform:translate(-50%,-50%)"></div>
    <div class="position-absolute rounded-circle bg-warning circle25"
      style="width:4vh;height:4vh;left:5%;top:10%; transform:translate(-50%,-50%)"></div>
    <div iv class="position-absolute text-white " style="left:50%;top:85%;transform: translate(-50%,0%)">
      <a :href="discoverOurLabUrl" class="px-3 py-2 font-vw-1-5 bg-dark-blue border-radius-4vw text-white fade25 pointer"
        style="border-radious:1.5vw; opacity:0">
        Click here to Discover Our Lab
      </a>
    </div>
    <div iv class="position-absolute text-white text-center"
      style="width:40%;left:50%;top:62.5%;transform: translate(-50%,-0%)">
      <p class="font-vw-1-35 text25" style="opacity:0; ">
        TRIUMF marked its 50th anniversary in 2018, celebrating a half century
        of discovery, innovation, and community for Canada and people around the
        world. Join us as we continue our journey.
      </p>
    </div>
  </div>
</template>

<script>
import anime from "animejs"
import { normalize } from "@/utils/ranges"
import { slidePositionPlain } from "@/utils/slidePositioning"
export default {
  props: ["globalUnits", "slideIndex"],
  data() {
    return {
      discoverOurLabUrl: process.env.VUE_APP_DISCOVER_OUR_LAB_URL
    }
  },
  computed: {
    slidePosition() {
      //console.log("SLIDES INDEX", this.slideIndex)
      return slidePositionPlain(this.globalUnits, this.slideIndex)
    },
    innerPosition() {
      return (
        (this.globalUnits - this.slideIndex.start) /
        (this.slideIndex.end - this.slideIndex.start)
      )
    }
  },
  mounted() { },
  methods: {
    finalLine(percentage) {
      let animation = anime
        .timeline({
          autoplay: false,
          loop: false,
          endDelay: 0
        })
        .add({
          targets: ".circle25",
          scale: [0, 1],
          easing: "easeInOutSine",
          duration: 300
        })
        .add({
          targets: ".finalLine path",
          strokeDashoffset: [anime.setDashoffset, 0],
          easing: "easeInOutSine",
          duration: 600,
          delay: (el, i) => 1500 + i * 250
        })

      var progress =
        percentage <= 0.5 ? percentage * 2 : normalize(percentage, 0.5, 1, 1, 0)
      animation.seek(animation.duration * progress)
    },
    line25Move(percentage) {
      let animation = anime
        .timeline({
          autoplay: false,
          loop: false,
          endDelay: 0
        })
        .add({
          targets: [".circle25", ".finalLine"],
          left: ["5%", "-10%"],
          easing: "easeInOutSine",
          duration: 300
        })

      animation.seek(animation.duration * percentage)
    },
    slide25(percentage) {
      let animation = anime.timeline({
        autoplay: false,
        loop: false,
        endDelay: 500
      })

      animation
        .add({
          targets: ".fade25",

          translateY: ["-30%", "0%"],
          opacity: [0, 1],
          duration: 100,
          easing: "easeInOutSine",
          delay: (el, i) => 5000 + 30 * i
        })
        .add({
          targets: ".text25",

          opacity: [0, 1],

          translateY: ["35%", "0%"],
          duration: 100,
          easing: "easeInOutSine"
        })
      var progress =
        percentage <= 0.5 ? percentage * 2 : normalize(percentage, 0.5, 1, 1, 0)
      animation.seek(animation.duration * progress)
    }
  },
  watch: {
    innerPosition() {
      this.finalLine(this.innerPosition)
      this.line25Move(this.innerPosition)
      this.slide25(this.innerPosition)
    }
  }
}
</script>
<style lang="scss" scoped>
$star-color-A: white;
$star-color-B: rgba(255, 255, 255, 0.1);

#sky {
  transition: all 3s ease;
  transform: translateZ(0);
}
</style>
